export default [
    {
        path: '/member/list',
        name: 'member-list',
        component: () => import('@/views/member/List.vue'),
        meta: {
            pageTitle: 'Member Management'
        },
    },
    {
        path: '/member/:id',
        name: 'member-detail',
        component: () => import('@/views/member/Detail.vue'),
        meta: {
            pageTitle: 'Member Details',
            breadcrumb: [
                {
                    text: 'Member Management',
                    to: {name: 'member-list'}
                },
                {
                    text: 'Details',
                    active: true,
                },
            ],
        },
    },
]
