import Vue from 'vue'
import axios from 'axios'
import {$themeConfig} from "@themeConfig"

const { appApiUrl } = $themeConfig.app

const axiosIns = axios.create({
    baseURL: appApiUrl,
    timeout: 3600000,
})

Vue.prototype.$http = axiosIns

export default axiosIns
