import Vue from 'vue'
import VueRouter from 'vue-router'

import auth from './routes/auth'
import common from './routes/common'
import system from './routes/system'
import cms from './routes/cms'
import member from './routes/member'
import {canNavigate} from "@/libs/acl/routeProtection";
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'


Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {path: '/', redirect: {name: 'home'}},
        ...auth,
        ...common,
        ...system,
        ...cms,
        ...member,
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()
    if (!canNavigate(to)) {
        if (!isLoggedIn) return next({name: 'auth-login'})
        return next({name: 'error-not-authorized'})
    }

    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

    return next()
})

router.afterEach((to) => {
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
    Vue.nextTick(() => {
        document.title = to.meta.pageTitle + ' | Finished Basements' || 'Finished Basements';
    });
})

export default router
